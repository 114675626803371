.bg-orange{
    background-color: #F16620;
    color: #ffffff;

    &.active {
        background-color: #dddddd!important;
        color: #1a1e21!important;
        border-color: #1a1e21!important;
    }
}

.bg-orange-important{
    background-color: #F16620!important;
    color: #ffffff!important;

    &.active {
        background-color: #dddddd!important;
        color: #1a1e21!important;
        border-color: #1a1e21!important;
    }
}

.border-orange{
    border-color: #F16620!important;
}

.group-hover-orange{
    a:hover, li:hover {
        background-color: #F16620;
        color: #ffffff;
    }
}
