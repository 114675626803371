.card-radius{
    border-radius: 15px!important;
}

.bg-slide-orange{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,f16620+100&0+0,0+47,0.5+75,1+89,1+100 */
    background: -moz-linear-gradient(45deg,  rgba(125,185,232,0) 0%, rgba(180,146,138,0) 47%, rgba(212,123,82,0.5) 75%, rgba(228,111,54,1) 89%, rgba(241,102,32,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg,  rgba(125,185,232,0) 0%,rgba(180,146,138,0) 47%,rgba(212,123,82,0.5) 75%,rgba(228,111,54,1) 89%,rgba(241,102,32,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg,  rgba(125,185,232,0) 0%,rgba(180,146,138,0) 47%,rgba(212,123,82,0.5) 75%,rgba(228,111,54,1) 89%,rgba(241,102,32,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#f16620',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    border-top-right-radius: 15px!important;

    > img {
        height: 40px;
    }
}

.bg-slide-light-blue{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,3190a0+100&0+0,0+47,0.5+75,1+89,1+100 */
    background: -moz-linear-gradient(45deg,  rgba(125,185,232,0) 0%, rgba(89,166,198,0) 47%, rgba(68,154,178,0.5) 75%, rgba(57,148,168,1) 89%, rgba(49,144,160,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg,  rgba(125,185,232,0) 0%,rgba(89,166,198,0) 47%,rgba(68,154,178,0.5) 75%,rgba(57,148,168,1) 89%,rgba(49,144,160,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg,  rgba(125,185,232,0) 0%,rgba(89,166,198,0) 47%,rgba(68,154,178,0.5) 75%,rgba(57,148,168,1) 89%,rgba(49,144,160,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#3190a0',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    border-top-right-radius: 15px!important;

    > img {
        height: 40px;
    }
}

.bg-slide-dark-blue{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,113b58+100&0+0,0+47,0.5+75,1+89,1+100 */
    background: -moz-linear-gradient(45deg,  rgba(125,185,232,0) 0%, rgba(74,126,164,0) 47%, rgba(44,91,124,0.5) 75%, rgba(29,73,104,1) 89%, rgba(17,59,88,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg,  rgba(125,185,232,0) 0%,rgba(74,126,164,0) 47%,rgba(44,91,124,0.5) 75%,rgba(29,73,104,1) 89%,rgba(17,59,88,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg,  rgba(125,185,232,0) 0%,rgba(74,126,164,0) 47%,rgba(44,91,124,0.5) 75%,rgba(29,73,104,1) 89%,rgba(17,59,88,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#113b58',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    border-top-right-radius: 15px!important;

    > img {
        height: 40px;
    }
}

.amount-credit{
    margin: 5px 0;
    color: #f16620;
    font-weight: bolder;
    text-shadow: 1px 1px 2px #333333;
}

.amount-credit-light-blue{
    margin: 5px 0;
    color: #35617f;
    font-weight: bolder;
    text-shadow: 1px 1px 2px #333333;
}

.amount-credit-dark-blue{
    margin: 5px 0;
    color: #113b58;
    font-weight: bolder;
    text-shadow: 1px 1px 2px #333333;
}
