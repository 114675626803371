.btn-orange{
    color: #ffffff;
    background-color: #F16620;
    border-color: #F0661F;
    border-radius: 6px;

    &:hover{
        color: #ffffff!important;
        background-color: #F0661F!important;
        border-color: #ffffff;
    }
}

.btn-outline-orange{
    color: #F16620;
    border-color: #F0661F;

    &:hover, &:active{
        color: #ffffff;
        background-color: #F0661F;
    }
}

.btn-check:checked + .btn-outline-orange,
.btn-check:active + .btn-outline-orange,
.btn-outline-orange:active,
.btn-outline-orange.active,
.btn-outline-orange.dropdown-toggle.show {
    color: #ffffff;
    background-color: #F0661F;
}

.nav-pills-orange{

    >.nav-item{
        >.nav-link {
            margin: 0 6px;
            color: #F16620;
            border: 1px solid transparent;

            &:hover{
                border-color: #F0661F;
            }
        }

        >.nav-link.active {
            color: #ffffff;
            background-color: #F0661F;
        }
    }
}

.underline-orange{
    padding-bottom: 0;
    border-bottom: 4px solid #F16620;
}
