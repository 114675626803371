@import "resources/css/bootstrap";
@import "praticheTopBar";
@import "ourServiceDropDownList";
@import "../../Common/orangeBackgound";
@import "footer";
@import "../../Form/ricariche";
@import "../../Common/orangeButton";
@import "../../Common/submitButton";

.container-mobile-margin{
    @include media-breakpoint-down(md){
        margin-top: 130px!important;
    }
}

@include media-breakpoint-between(sm, lg) {
    #rightPraticheBarCol{
        width: 68%;
    }
}

.subtitleH2Description {
    font-size: 1.5rem;
    font-weight: 300;
}
