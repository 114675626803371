@import "resources/css/bootstrap";

.hidden-until-md{
    @include media-breakpoint-down(md){
        display: none;
    }
}

.footer-container {
    min-height: 200px;
    width: 100%;
    background-color: #fefefe;
    border-top: 1px solid #ccc;
    color: #888;
    position: relative;
}

#goUpButton{
    position: absolute;
    top: 0;
    right: 30px;
    background-color: #F16620;
    border: #efefef;
    color: #efefef;
}

#footer-info-container{
    font-size: 15px;
    border-right: 1px solid #ccc;
}

#footer-logo{
    width: 180px;
}

#aruba-logo{
    height: 25px;
}

.footer-list{

    h5{
        font-size: 13px;
        color: #666;
    }

    ul{
        list-style-type: none;
        padding-left: 0;
        color: #888;
        font-size: 13px;

        li{
            a{
                color: #888;
                text-decoration: none;

                &:hover{
                    color: #888;
                    text-decoration: underline;
                }
            }
        }
    }
}
