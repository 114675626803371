@keyframes btn-pratiche-hover-in {
    from { width: 0%; left: 50% }
    to { width: 100%; left: 0% }
}

.btn-pratiche-fixed{
    padding-top: 11px;
    padding-bottom: 13px;
    width: 100%;
    background-color: #F77312!important;
    color: #ffffff!important;
    text-transform: uppercase;
    position: relative;
    border-radius: 5px;
    z-index: 2;
}

.btn-pratiche-disabled {
    padding-top: 11px;
    padding-bottom: 13px;
    width: 100%;
    background-color: #6c757d!important;
    color: #ffffff!important;
    text-transform: uppercase;
    position: relative;
    border-radius: 5px;
    z-index: 2;
}

.btn-pratiche,.btn-pratiche:active{
    padding-top: 11px;
    padding-bottom: 13px;
    width: 100%;
    background-color: #F77312!important;
    color: #ffffff!important;
    text-transform: uppercase;
    position: relative;
    border-radius: 5px;
    z-index: 2;

    &:hover{
        background-color: #F77312!important;
        color: #ffffff!important;
        font-weight: bold;
        box-shadow: 1px 3px 6px 0px #ba500f;

        .hover-pratiche-btn{
            background-color: #ffffff;
            animation-name: btn-pratiche-hover-in;
            animation-duration: 1s;
            width: 100%;
            left: 0%;
        }
    }
}

.hover-pratiche-btn{
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 5px;
    background-color: transparent;
    border-radius: 10px;
    z-index: 1;
}
