@import "resources/css/bootstrap";

.praticheBarPreAnimation{
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 999;
}

.customBlackTopBarNavLink{
    font-family: Lato, sans-serif;
    font-size: 11px!important;
    font-weight: 700!important;
    padding-bottom: 0!important;
    padding-top: 0!important;

    &:hover{
        color: #fff!important;
    }
}

.customWhiteTopBarNavLink{
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 700;

    &:hover{
        color: #F16620!important;
    }
}

#blackTopBar {

    max-height: 35px;

    @include media-breakpoint-up(md){
        background-image: url("../../../../../public/icon/bg-logo.png");
        background-repeat: no-repeat;
    }

    .container-fluid{

        padding-left: 50px;

        button.navbar-toggler{
            font-size: 11px;
            padding: 0 12px;
        }
    }
}

#whiteTopBar{
    background-color: #F2F2F1!important;
}

#collapseWhiteTopBar{

    @include media-breakpoint-up(md) {
        padding-left: 50px;
    }

    ul.navbar-nav{
      li.dropdown{
          div.dropdown-menu{
              border-radius: 3px;
              border: 1px solid #efefef;
              box-shadow: 1px 0 12px 0.5px #efefef;
              padding: 0;
          }
      }
    }
}

#collapseBlackTopBar{

    &.show{
        ul {
            margin-top: 12px;
        }
    }

    @include media-breakpoint-down(md){
        z-index: 9;
        background-color: rgb(37, 37, 41);
        position: absolute;
        width: 100%;
        top: 34px;
        left: 0;
        padding-left: 25px;
    }
}

#logoPraticheTopBar{

    @include media-breakpoint-up(md){
        text-align: center;
        padding-bottom: 12px;
    }

    background-color: #F2F2F1;

    a{
        img{
            padding-top: 8px;
            padding-left: 10px;
        }
    }
}

#rightPraticheBarCol{
    padding-left: 0;
    padding-right: 0;
}

#blackTopBarEndSection{

    @include media-breakpoint-up(md){
        right: 10px;
        position: absolute;
        flex-direction: row;
    }

    .hidden-until-md{
        @include media-breakpoint-down(md){
            display: none;
        }
    }

    li{
        a{
            font-family: Lato, sans-serif;
            font-size: 11px;
            font-weight: 700;
            padding: 0 3px;
            color: #ffffff;
            border-right: 1px solid #aaa;
        }

        &:last-child{
            a{
                border-right: none;
            }
        }
    }
}
