@import "resources/css/bootstrap";

#praticheServiceDropdownMenu {
    overflow-y: auto;
}

@include media-breakpoint-down(sm) {

    #praticheServiceDropdownMenu {
        width: 100vw;
    }
}

@include media-breakpoint-only(md) {

    #praticheServiceDropdownMenu {
        width: 75vw;
        margin-left: -20vw;
    }
}

@include media-breakpoint-down(lg) {
    .office-title-list{
        font-size: 15px;
    }

    .office-description-list{
        div{
            h6{
                font-size: 14px;
            }
        }
    }

    #ourServiceListGroup{
        li{
            font-size: 14px;
        }
    }
}

@include media-breakpoint-up(lg) {
    #praticheServiceDropdownMenu {
        width: 60vw;
    }
}

#ourServiceListGroup{

    li{

        &:hover{
            cursor:pointer;
        }
    }
}
